import {
    inject,
    Injectable,
    isDevMode,
    makeStateKey,
    Renderer2,
    RendererFactory2,
    TransferState,
    Type,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Data } from '@angular/router';
import Client, {
    ISbStories,
    ISbStoriesParams,
    ISbStory,
    ISbStoryData,
    ISbStoryParams,
    ISbComponentType,
    SbHelpers, ISbError
} from 'storyblok-js-client';
import { richTextResolver } from '@storyblok/richtext';
import { PlatformService, WINDOW } from '@seven1/angular/ssr';
import { STORYBLOK_CONFIG } from './storyblok.provider';
import {
    MissingComponentStroyblokError,
    StroyblokApiError,
} from './storyblok.errors';
import { ISbDataSourceEntries, ISbDataSourceEntry } from './storyblok.types';
const fallback_stories = {
    data: { cv: 0, links: [], rels: [], stories: [] },
    perPage: 0,
    total: 0,
    headers: undefined,
};

export function prefixSlug(prefix: string, slug?: string): string {
    if (slug?.length && !slug.startsWith('/')) {
        slug = '/' + slug;
    }
    return  prefix + (slug || '');
}

export function unprefixSlug(slug?: string, prefix?: string): string {
    if (slug?.length && slug.startsWith('/')) {
        slug = slug.substring(1, slug.length);
    }
    if (slug?.length && prefix?.length && slug?.startsWith(prefix)) {
        slug = slug.substring(prefix.length, slug.length);
    }
    return slug || '';
}

export function prefixListOfSlugs(prefix: string, slugs?: string): string | undefined {
    return slugs
        ?.split(',')
        .filter(slug => slug !== '')
        .map(slug => {
            if (!slug.startsWith('/')) {
                slug = '/' + slug;
            }
            if (!slug.startsWith(prefix)) {
                return prefix + slug;
            }
            return slug;
        })
        .join(',');
}

@Injectable({
    providedIn: 'root',
})
export class StoryblokService {
    config = inject(STORYBLOK_CONFIG);
    private _renderer?: Renderer2;
    private _platform = inject(PlatformService);
    private _transferState = inject(TransferState);
    private _window = inject(WINDOW);
    private _document = inject(DOCUMENT);
    public helpers = new SbHelpers();
    public richTextResolver = richTextResolver(this.config.richText);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bridge?: any;
    private _client = new Client(this.config);

    constructor(rendererFactory: RendererFactory2) {
        this._renderer = rendererFactory.createRenderer(null, null);
    }

    /**
     * Load storyblok bridge - check if there is a script with the id `storyblokBridge`:
     * - if yes, call `callback` with `onInput`
     * - if no, create it and then call `callback` with `onInput`
     *
     * @param callback - todo
     * @param onInput - todo
     * */
    private _loadBridge(
        callback: (onInput: (data: unknown) => void) => void,
        onInput: (data: unknown) => void,
    ): void {
        const existingScript =
            this._document?.getElementById('storyblokBridge');
        if (!existingScript) {
            const script = this._renderer?.createElement('script');
            if (script) {
                script.src = '//app.storyblok.com/f/storyblok-v2-latest.js';
                script.id = 'storyblokBridge';
                this._renderer?.appendChild(this._document.body, script);
                script.onload = () => {
                    if (isDevMode()) console.log('Loaded Story bridge script');
                    callback(onInput);
                };
            }
        } else {
            callback(onInput);
        }
    }

    /**
     * Init storyblok bridge - get bridge from `window` handle events:
     * - **`change`, `publish`:** reload location to update story
     * - **`input`:** todo: reload via api directly (draft or published, depending if in editor)
     *
     * @param self - instance of the `StoryblokService`
     * @param onInput - todo
     * */
    private _initBridge(
        self: StoryblokService,
        onInput: (data: unknown) => void,
    ): void {
        // @ts-expect-error StoryblokBridge should be part of window when bridge is inited
        const { StoryblokBridge, location } = this._window;
        self.bridge = new StoryblokBridge();
        self.bridge.on(['published', 'change'], (event: {slugChanged: boolean}) => {
            if (!event.slugChanged) {
                location.reload();
            }
        });
        self.bridge.on(['input'], (event: unknown) => {
            onInput(event);
        });
        // todo - use in api calls directly
        self.bridge.pingEditor(() => {
            if (self.bridge.isInEditor()) {
                //  load the draft version
            } else {
                // load the published version
            }
        });
        if (isDevMode()) console.log('Storyblok editor initialized');
    }

    /**
     * Load and init bridge, if we are inside storyblok
     *
     * */
    initEditor(onInput: (data: Data & {story: ISbStory | ISbError} | unknown) => void): void {
        if (
            this._platform.isBrowser &&
            this._window?.location.search.includes('_storyblok')
        ) {
            if (isDevMode()) console.info('Storyblok bridge init');
            this._loadBridge(
                onInput => this._initBridge(this, onInput),
                onInput,
            );
        }
    }

    /**
     * Fetch stories of storyblok with `ISbStoriesParams` (search, pagination, filtering, sorting)
     *
     * @link https://www.storyblok.com/docs/api/content-delivery/v1#core-resources/stories/stories
     * @param parameter - `ISbStoriesParams`
     * @returns a `CmsStories` or null, if not existent
     * */
    async getStories<Content = ISbComponentType<string> & { [index: string]: any },
    >(parameter: ISbStoriesParams): Promise<ISbStories<Content> | null> {
        let res: ISbStories<Content>;
        const storiesKey = makeStateKey<ISbStories<Content>>(
            `stories-${parameter.by_uuids}-${parameter.content_type}-${parameter.sort_by}-${JSON.stringify(parameter.filter_query)}`
        );
        // story base path - always start with storyBasePath
        if (this.config.storyBasePath !== '/') {
            if (!(parameter.starts_with || '').startsWith(this.config.storyBasePath)) {
                parameter.starts_with = prefixSlug(this.config.storyBasePath, parameter.starts_with)
            }
            if (parameter.by_slugs?.length) {
                parameter.by_slugs = prefixListOfSlugs(this.config.storyBasePath, parameter.by_slugs);
            }
            if (parameter.excluding_slugs?.length) {
                parameter.excluding_slugs = prefixListOfSlugs(this.config.storyBasePath, parameter.excluding_slugs);
            }
        }
        if (!parameter.version) {
            parameter.version = this.config.version;
        }
        if (this._platform.isServer) {
            try {
                res = (await this._client.getStories(parameter)) as ISbStories<Content>;
            } catch (e) {
                console.error(e);
                return null;
            }
            this._transferState.set(storiesKey, res);
        } else {
            //  if (isPlatformBrowser(this.platformId))
            if (this._transferState.hasKey(storiesKey)) {
                res = this._transferState.get(storiesKey, fallback_stories);
            } else {
                res = (await this._client.getStories(parameter)) as ISbStories<Content>;
            }
        }
        return res;
    }

    /**
     * Fetch a single story from Storyblok with `ISbStoryParams` (find_by, version, resolve_links, resolve_relations, language)
     *
     * @param slug - param appended to the stories url `/v2/cdn/stories/(:full_slug|:id|:uuid)`
     * @param parameter - `ISbStoryParams`
     * @returns a `ISbStoryData` or null, if not existent
     * */
    public async getStory<
        T extends ISbComponentType<string> = ISbComponentType<string>,
    >(slug: string, parameter?: ISbStoryParams): Promise<ISbStoryData<T>> {
        const storyKey = makeStateKey<ISbStoryData>('story/' + slug);

        // story base path
        if (!slug.startsWith(this.config.storyBasePath)) {
            slug = prefixSlug(this.config.storyBasePath, slug);
        }

        if (slug.startsWith('/')) {
            slug = slug.substring(1);
        }

        parameter = parameter || {
            version: this.config.version,
        };

        this.bridge?.pingEditor(() => {
            if (this.bridge.isInEditor()) {
                //  load the draft version
                parameter = { ...parameter, version: 'draft' };
            }
        });

        let res: ISbStory;
        let storyData: ISbStoryData<T>;
        if (this._platform.isServer || !this._transferState.hasKey(storyKey)) {
            if (isDevMode()) console.log('getStory from API', slug, parameter);

            try {
                res = await this._client.getStory(slug, parameter);
            } catch (e) {
                console.error(e);
                throw new Error(`Couldn't get story with slug '${slug}'`);
            }
            storyData = res.data.story as ISbStoryData<T>;
            this._transferState.set(storyKey, storyData);
        } else {
            storyData = this._transferState.get(
                storyKey,
                undefined,
            ) as ISbStoryData<T>;
        }

        return storyData;
    }

    private async _fetchDataSourceEntries(
        datasource: string,
    ): Promise<ISbDataSourceEntry[]> {
        const res = await this._client.get('cdn/datasource_entries', {
            datasource,
        }) as ISbDataSourceEntries;
        if (res) {
            return res.data.datasource_entries;
        }
        return [];
    }

    /**
     * Fetch
     *
     * @param datasource - slug of the datasource, `datasource` param appended to the datasource url `/v2/cdn/datasource_entries?datasource=${datasource}`
     * @returns a `ISbDataSourceEntry[]` or an empty array, if not existent
     * */
    public async getDatasourceEntries(
        datasource: string,
    ): Promise<ISbDataSourceEntry[] | null> {
        const dataKey = makeStateKey<ISbDataSourceEntry[]>(
            'datasource/' + datasource,
        );

        let data: ISbDataSourceEntry[] | null = null;
        if (this._platform.isServer || !this._transferState.hasKey(dataKey)) {
            try {
                data = await this._fetchDataSourceEntries(datasource);
            } catch (e) {
                throw new StroyblokApiError(
                    'cdn/datasource_entries?datasource=' + datasource,
                    e,
                );
            }
            if (data?.length) {
                this._transferState.set(dataKey, data);
            }
        } else {
            //  if (isPlatformBrowser(this.platformId))
            data = this._transferState.get(dataKey, []);
            if (!data.length) {
                data = await this._fetchDataSourceEntries(datasource);
            }
        }
        return data;
    }

    /**
     * Resolve a component with a component name
     *
     * @param component - the name / key of the component, configured in the Storyblok config
     * @returns a component class of `Type<T>`
     * */
    public resolveComponent<T = unknown>(component: string): Type<T> {
        const res = this.config.components[component];
        if (!res) {
            throw new MissingComponentStroyblokError(component);
        }
        return res as Type<T>;
    }

    /**
     * Set an element editable, setting attributes `data-blok-c` and `data-blok-uid`
     * with the json from the `editableString`
     *
     * @param nativeElement - the native element, where the attributes should be set
     * @param editableString - the storyblok `editable` string (includes `<!--#storyblok# ... -->`)
     * */
    public setElementEditable<E = unknown>(
        nativeElement: E,
        editableString: string,
    ): void {
        if (nativeElement && this._renderer && editableString) {
            const options = JSON.parse(
                editableString
                    .replace('<!--#storyblok#', '')
                    .replace('-->', ''),
            );
            this._renderer.setAttribute(
                nativeElement,
                'data-blok-c',
                JSON.stringify(options),
            );
            this._renderer.setAttribute(
                nativeElement,
                'data-blok-uid',
                options.id + '-' + options.uid,
            );
        }
    }
}
